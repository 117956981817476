export default function company({next, store, from, router}) {

    let previousRouteName = from.name || 'dashboard';
    let company = store.state.company;

    if (_.isNull(company)) {
        return router.push({'name': previousRouteName});
    }


    return next();
}
