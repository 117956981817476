export default function hasIfCompany({next, store, from, router}) {

    let user = store.state.user;

    if (user.type !== 'candidate') {
        if (_.isEmpty(user.companies)) {
            return router.push({name: 'company.create'});
        }
    }

    return next();
}
