import Vue from 'vue';
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// const messages = {
//     en : require('../../../lang/en'),
//     bn : require('../../../lang/bn'),
//     de : require('../../../lang/de'),
//     es : require('../../../lang/es'),
//     it : require('../../../lang/it'),
//     no : require('../../../lang/no'),
//     fr : require('../../../lang/fr'),
//     hi : require('../../../lang/hi'),
//     ja : require('../../../lang/ja'),
//     nl : require('../../../lang/nl'),
//     pt : require('../../../lang/pt'),
//     ru : require('../../../lang/ru'),
//     tc : require('../../../lang/tc'),
//     sc : require('../../../lang/sc')
// };

function loadLocaleMessages () {
    // const locales = require.context('../../../lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const locales = require.context('../lang', true, /en.json$/i);
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    });
    return messages
}



export const i18n = new VueI18n({
    messages : loadLocaleMessages(),
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn : true
});

const loadedLanguages = ['en'];


function setI18nLanguage (lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(`../lang/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}


