import Vue from 'vue';
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter);

const Login = () => import("../../pages/Login.vue");
// import Login from "../../pages/Login.vue");
const ForgetPassword = () => import("../../pages/ForgetPassword.vue");
const ResetPassword = () => import("../../pages/ResetPassword.vue");
const Dashboard = () => import("../../pages/Dashboard.vue");
const Registration = () => import('../../pages/Registration.vue');
const Subscribe = () => import('../../pages/Subscribe.vue');
const Onboard = () => import('../../pages/Onboard.vue');
const PublishedJobs = () => import("../../pages/jobs/PublishedJobs.vue");
const DraftJobs = () => import("../../pages/jobs/DraftJobs.vue");
const ArchivedJobs = () => import("../../pages/jobs/ArchivedJobs.vue");
const Logout = () => import("../../pages/Logout.vue");
const Candidates = () => import("../../pages/company/Candidates.vue");
const CompanyBasicInfo = () => import("../../pages/company/setting/BasicInfo.vue");
const CandidateApplySetting = () => import("../../pages/company/setting/ApplySetting.vue");
const NotificationSetting = () => import("../../pages/company/setting/Notification.vue");
const CompanyTemplates = () => import("../../pages/company/setting/Templates.vue");
const CompanyPhoto = () => import("../../pages/company/setting/Photo.vue");
const CompanyUsers = () => import("../../pages/company/setting/Managers.vue");
const CompanyUsersAdd = () => import("../../pages/company/setting/ManagersAdd.vue");
const CompanyUsersUpdate = () => import("../../pages/company/setting/ManagersUpdate.vue");
const CompanyCategories = () => import("../../pages/company/setting/Categories.vue");
const CompanyAppKey = () => import("../../pages/company/setting/AppKey.vue");
const CompanyCustomDomain = () => import("../../pages/company/setting/CustomDomain.vue");
const CompanyPipeline = () => import("../../pages/company/setting/PipelineTemplate.vue");
const CompanyNotification = () => import("../../pages/company/CompanyNotification.vue");
const CompanyEmailSettings = () => import("../../pages/company/setting/EmailSetup.vue");
const CompanySmtpSettings = () => import("../../pages/company/setting/SmtpSettings.vue");
const Home = () => import("../../pages/Home.vue");
const MyAccount = () => import("../../layouts/MyAccount.vue");
const Subscription = () => import('../../pages/myaccount/Subscription.vue');
const Billing = () => import('../../pages/myaccount/Billing.vue');
const ChangePassword = () => import('../../pages/myaccount/ChangePassword.vue');
const AccountInfo = () => import('../../pages/myaccount/AccountInfo.vue');
const PaymentHistory = () => import('../../pages/myaccount/PaymentHistory.vue');
const App = () => import("../../components/App.vue");
const CreateJobInformation = () => import("../../pages/jobs/CreateJobInformation.vue");
const CreateJobScreeningQuestion = () => import("../../pages/jobs/CreateJobScreeningQuestion.vue");
const JobCreate = () => import("../../layouts/JobCreate.vue");
const JobEdit = () => import("../../layouts/JobEdit.vue");
const CreateJobQuiz = () => import("../../pages/jobs/CreateJobQuiz.vue");
const JobCandidates = () => import('../../pages/jobs/Candidate.vue');
const CreateCompany = () => import("../../pages/company/Create.vue");
const CandidateProfile = () => import("../../layouts/CandidateProfile.vue");
const CandidateBasicInfo = () => import("../../pages/candidate/profile/basicInfo/List");
const CandidateBasicInfoForm = () => import("../../pages/candidate/profile/basicInfo/Form");
const Checkout = () => import("../../pages/myaccount/Checkout.vue");
const AppliedJobs = () => import("../../pages/candidate/AppliedJobs.vue");
const UnfinishedJobs = () => import("../../pages/candidate/UnfinishedJobs.vue");
const Resume = () => import("../../pages/candidate/Resume.vue");

const JobPipelineDefault = () => import("../../pages/jobs/pipeline/DefaultView.vue");
const JobPipelineBoard = () => import("../../pages/jobs/pipeline/BoardView.vue");

const Conversations = () => import("../../pages/conversations/Conversations.vue");

const QuestionSet = () => import("../../pages/question-set/QuestionSet.vue");
const CreateQuestionSet = () => import("../../pages/question-set/CreateQuestionSet.vue");
const EmailVerify = () => import("../../pages/EmailVerify.vue");
const PageNotFound = () => import("../../pages/error/PageNotFound.vue");
const CandidateDetails = () => import("../../pages/company/CandidateDetails");
const CheckoutSuccess = () => import("../../pages/myaccount/CheckoutSuccess");
const RemoteInterviewCallback = () => import("../../pages/RemoteInterviewCallback");
const AssessmentLogin = () => import("../../pages/AssessmentLogin");
const UserAppSetup = () => import("../../pages/company/setting/UserApp");
const Integrations = () => import("../../pages/company/setting/Integration");
const CompanyAiSetup = () => import("../../pages/company/setting/AiSetup");
const CompanyActivityLog = () => import("../../pages/company/setting/ActivityLog");
const CompanyVerification = () => import("../../pages/company/setting/Verification");
const CandidateEmployment = () => import("../../pages/candidate/profile/employment/List");
const CandidateEmploymentForm = () => import("../../pages/candidate/profile/employment/Form");
const CandidateEducation = () => import("../../pages/candidate/profile/education/List");
const CandidateEducationForm = () => import("../../pages/candidate/profile/education/Form");
const Assessments = () => import("../../pages/assessment/Assessments");
const AssessmentForm = () => import("../../pages/assessment/AssessmentForm");


import { guest, auth, candidate as OnlyCandidate, company as OnlyCompany, canCreateCompany, hasPackage, hasIfCompany } from '../middleware';
import { execMiddleware } from "../middleware/_main";
import redirectToDashboard from "../middleware/redirectToDashboard";

let Router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                middleware: [auth]
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: { auth: true }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [guest]
            }
        },
        {
            path: '/forgot-password',
            name: 'password.forget',
            component: ForgetPassword,
            meta: {
                middleware: [guest]
            }
        },
        {
            path: '/onboard',
            name: 'invitation.login',
            component: Onboard,
            meta: {
                middleware: [guest]
            }
        },
        {
            path: '/password/reset/:token',
            name: 'password.reset',
            component: ResetPassword,
            meta: {
                middleware: [guest]
            }
        },
        {
            path: '/registration',
            name: 'registration',
            component: Registration,
            meta: {
                middleware: [guest]
            }
        },
        {
            path: '/subscribe',
            name: 'subscribe',
            component: Subscribe,
            meta: {
                middleware: [auth]
            }
        },
        {
            path: '/verify/email/:id',
            name: 'email.verify',
            component: EmailVerify
        },
        {
            path: '/remote-interview/:client',
            name: 'remote-interview.callback',
            component: RemoteInterviewCallback,
        },
        {
            path: '/redirect-to-dashboard',
            name: 'redirect-to-dashboard',
            component: App,
            meta: {
                middleware: [redirectToDashboard]
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                middleware: [auth, hasPackage, hasIfCompany]
            }
        },
        {
            path: '/applicant/:id',
            name: 'applicant.details',
            component: CandidateDetails,
            meta: {
                middleware: [auth, OnlyCompany]
            }
        },
        {
            path: '/company/create',
            name: 'company.create',
            component: CreateCompany,
            meta: {
                middleware: [auth, hasPackage, canCreateCompany]
            }
        },
        {
            path: '/notification',
            name: 'notification',
            component: CompanyNotification,
            meta: {
                middleware: [auth]
            }
        },
        {
            path: '/company/candidates',
            name: 'company.candidates',
            component: Candidates,
            meta: {
                middleware: [auth, OnlyCompany]
            }
        },
        {
            path: '/job',
            name: 'job',
            redirect: {
                name: 'jobs.published'
            },
            component: App,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                    path: 'published',
                    name: 'jobs.published',
                    component: PublishedJobs
                },
                {
                    path: 'draft',
                    name: 'jobs.draft',
                    component: DraftJobs
                },
                {
                    path: 'archived',
                    name: 'jobs.archived',
                    component: ArchivedJobs
                },
                {
                    path: ':jobId/candidate',
                    name: 'job.candidates',
                    component: JobCandidates
                },
                {
                    path: ':jobId/pipeline',
                    name: 'job.pipeline',
                    component: JobPipelineDefault
                },
                {
                    path: ':jobId/pipeline/board',
                    name: 'job.pipeline.board',
                    component: JobPipelineBoard
                },
            ]
        },
        {
            path: '/job',
            component: JobCreate,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                path: 'create',
                name: 'job.create',
                component: CreateJobInformation
            }]
        },
        {
            path: '/job',
            component: JobEdit,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                    path: ':id/edit',
                    name: 'job.edit',
                    component: CreateJobInformation
                },
                {
                    path: ':id/edit/screening',
                    name: 'job.screening',
                    component: CreateJobScreeningQuestion
                },
                {
                    path: ':id/edit/quiz',
                    name: 'job.quiz',
                    component: CreateJobQuiz
                },

            ]
        },
        {
            path: '/company/conversations/:id?/:new?',
            name: 'conversations',
            component: Conversations,
            meta: {
                middleware: [auth, OnlyCompany]
            }
        },
        {
            path: "/company/setting",
            component: App,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                    path: '',
                    name: 'company.setting.basic',
                    component: CompanyBasicInfo
                },
                {
                    path: 'photo',
                    name: 'company.setting.photo',
                    component: CompanyPhoto
                },
                {
                    path: 'templates',
                    name: 'company.setting.templates',
                    component: CompanyTemplates
                },
                {
                    path: 'user',
                    name: 'company.setting.user',
                    component: CompanyUsers
                },
                {
                    path: 'user/add',
                    name: 'company.setting.user.add',
                    component: CompanyUsersAdd
                },
                {
                    path: 'user/:id/edit',
                    name: 'company.setting.user.update',
                    component: CompanyUsersUpdate
                },
                {
                    path: 'category-skill',
                    name: 'company.setting.category-skill',
                    component: CompanyCategories
                },
                {
                    path: 'app-key',
                    name: 'company.setting.app-key',
                    component: CompanyAppKey
                },
                {
                    path: 'custom-domain',
                    name: 'company.setting.custom-domain',
                    component: CompanyCustomDomain
                },
                {
                    path: 'pipeline',
                    name: 'company.setting.pipeline',
                    component: CompanyPipeline
                },
                {
                    path: 'email',
                    name: 'company.setting.email',
                    component: CompanyEmailSettings
                },
                {
                    path: 'email/smtp',
                    name: 'company.setting.email.smtp',
                    component: CompanySmtpSettings
                },
                {
                    path: 'integrations/:type',
                    name: 'company.setting.user-app',
                    component: UserAppSetup
                },
                {
                    path: 'integrations',
                    name: 'company.setting.integration',
                    component: Integrations
                },
                {
                    path: 'ai-setup',
                    name: 'company.setting.ai-setup',
                    component: CompanyAiSetup
                },
                {
                    path: 'verification',
                    name: 'company.setting.verification',
                    component: CompanyVerification
                },
                {
                    path: 'apply-setting',
                    name: 'company.setting.apply-setting',
                    component: CandidateApplySetting
                },
                {
                    path: 'notifications',
                    name: 'company.setting.notifications',
                    component: NotificationSetting
                },
                {
                    path: 'activity-log',
                    name: 'company.setting.activity-log',
                    component: CompanyActivityLog
                },
            ]
        },
        {
            path: "/company/question/group/",
            component: App,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                    path: '',
                    name: 'company.question.group',
                    component: QuestionSet
                },
                {
                    path: 'create',
                    name: 'company.question.group.create',
                    component: CreateQuestionSet
                },
                {
                    path: ':id/edit',
                    name: 'company.question.group.edit',
                    component: CreateQuestionSet
                },
            ]
        },
        {
            path: "/company/assessments/",
            component: App,
            meta: {
                middleware: [auth, OnlyCompany]
            },
            children: [
                {
                    path: '',
                    name: 'company.assessments',
                    component: Assessments
                },
                {
                    path: 'create',
                    name: 'company.assessments.create',
                    component: AssessmentForm
                },
                {
                    path: ':id/edit',
                    name: 'company.assessments.edit',
                    component: AssessmentForm
                },
            ]
        },
        {
            path: '/my-account',
            component: MyAccount,
            meta: {
                middleware: [auth]
            },
            children: [
                {
                    path: '',
                    name: 'my-account',
                    component: Subscription
                },
                {
                    path: 'billing',
                    name: 'my-account.billing',
                    component: Billing
                },
                {
                    path: 'change-password',
                    name: 'my-account.change-password',
                    component: ChangePassword
                },
                {
                    path: 'information',
                    name: 'my-account.information',
                    component: AccountInfo
                },
                {
                    path: 'payment-history',
                    name: 'my-account.payment-history',
                    component: PaymentHistory
                }
            ]
        },
        {
            path: '/my-account/checkout/:slug',
            component: App,
            meta: {
                middleware: [auth]
            },
            children: [
                {
                    path: '',
                    name: 'checkout',
                    component: Checkout
                },
                {
                    path: 'success',
                    name: 'checkout.success',
                    component: CheckoutSuccess
                },
                {
                    path: 'cancel',
                    name: 'checkout.cancel',
                    component: ChangePassword
                }
            ]
        },
        {
            path: "/candidate/profile",
            component: CandidateProfile,
            meta: {
                middleware: [auth, OnlyCandidate]
            },
            children: [
                {
                    path: '',
                    name: 'candidate.basic-info',
                    component: CandidateBasicInfo
                },
                {
                    path: 'edit',
                    name: 'candidate.basic-info.edit',
                    component: CandidateBasicInfoForm
                },
                {
                    path: 'qualifications',
                    name: 'candidate.qualifications',
                    component: CandidateEducation
                },
                {
                    path: 'qualifications/create',
                    name: 'candidate.qualifications.create',
                    component: CandidateEducationForm
                },
                {
                    path: 'qualifications/:id/edit',
                    name: 'candidate.qualifications.edit',
                    component: CandidateEducationForm
                },
                {
                    path: 'employments',
                    name: 'candidate.employments',
                    component: CandidateEmployment
                },
                {
                    path: 'employments/create',
                    name: 'candidate.employments.create',
                    component: CandidateEmploymentForm
                },
                {
                    path: 'employments/:id/edit',
                    name: 'candidate.employments.edit',
                    component: CandidateEmploymentForm
                }
            ]
        },
        {
            path: '/candidate',
            component: App,
            meta: {
                middleware: [auth, OnlyCandidate]
            },
            children: [
                {
                    path: 'resume',
                    name: 'candidate.resume',
                    component: Resume
                },
                {
                    path: 'applied-jobs',
                    name: 'candidate.applied-jobs',
                    component: AppliedJobs
                },
                {
                    path: 'unfinished-jobs',
                    name: 'candidate.unfinished-jobs',
                    component: UnfinishedJobs
                },
                {
                    path: 'assessment/:assessmentId',
                    name: 'candidate.assessment.login',
                    component: AssessmentLogin
                },
                {
                    path: 'conversations/:id?',
                    name: 'applicantConversations',
                    component: Conversations,
                },
            ]
        },
        {
            path: '/page-not-found',
            name: 'error.not-found',
            component: PageNotFound
        },
        {
            path: '*', // this route has to be the last route
            component: App,
            redirect: { name: 'error.not-found' }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                selector: to.hash
            }
        } else if(to.name === 'conversations' || to.name === 'applicantConversations') {
            return { x: window.scrollLeft, y: window.scrollTop }
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default execMiddleware(Router);
