require('bootstrap');
window._helper = require('./extra/helper').default;
import Vue from 'vue';
window.axios = require('axios');

import App from './components/App';
const SubmitButton = () => import("./components/buttons/SubmitButton");

import Router from './app/router';
import {store} from './app/store/index';
import {request} from './app/api/Client';
import {mapActions} from 'vuex';
import {USER_LOGGED_IN, USER_LOGGED_OUT} from './constants/action-type';
import mixin from "./extra/mixin";
import VueToast from 'vue-toast-notification';
import VuejsDialog from 'vuejs-dialog';
import VueProgressBar from 'vue-progressbar';
import {
    vueProgressBar as vueProgressBarOptions,
    vueToast as vueToastOptions,
    vueJsDialog as vueJsDialogOptions,
} from './config/options';
import {i18n, loadLanguageAsync} from './app/i18n';
import './utils/filter';
import VTooltip from 'v-tooltip'
import { directive as onClickaway } from 'vue-clickaway';

Vue.use(VueProgressBar, vueProgressBarOptions);
Vue.use(VueToast, vueToastOptions);
Vue.use(VuejsDialog, vueJsDialogOptions);

let opt = {
    defaultPopperOptions: {
        hideOnTargetClick: true
    }
};

Vue.use(VTooltip, opt);


Vue.component('submit-button', SubmitButton);
Vue.directive('on-click-away', onClickaway);
Vue.mixin(mixin);

export default new Vue({
    mixins: [mixin],
    router: Router,
    store,
    i18n,
    async beforeMount() {
        let token = _helper.getCookie('ej_token') || null;
        let user = this.$store.state.user || null;

        if (token) {
            let user = this.$store.state.user || null;
            if (user === null) {
                try {
                    let {data: {data}} = await request.get('user');
                    data['token'] = token;
                    this[USER_LOGGED_IN](data);
                } catch (e) {
                    await this.$router.push({name: 'registration'});
                }
            }
        } else {
            if (user !== null) {
                this[USER_LOGGED_OUT]();
                await this.$router.push({name: 'login'});
            }
        }

        let lang = this.$store.state.language || null;

        if (!_.isNull(lang)) {
            loadLanguageAsync(lang).then(() => {
            });
            // this.$i18n.locale = lang;
        }

    },
    methods: {
        ...mapActions([USER_LOGGED_IN, USER_LOGGED_OUT]),
    },
    render: h => h(App)
}).$mount('#easyJobsApp');