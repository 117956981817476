import {PERMISSIONS} from '../constants/enums';
import {store} from "../app/store";

export const ROUTE_PERMISSIONS = {
    [PERMISSIONS.JOB_VIEW]: [
        'jobs.published',
        'jobs.draft',
        'jobs.archived'
    ],
    [PERMISSIONS.JOB_MANAGEMENT]: [
        'job.create',
        'job.edit',
        'job.screening',
        'job.quiz',
        'job.pipeline',
        'job.pipeline.board'
    ],
    [PERMISSIONS.JOB_PUBLISH]: [],
    [PERMISSIONS.CANDIDATE_VIEW]: [
        'applicant.details',
        'job.candidates',
        'company.candidates'
    ],
    [PERMISSIONS.CANDIDATE_DELETE]: [],
    [PERMISSIONS.CANDIDATE_ORGANIZE]: [
        'job.pipeline',
        'job.pipeline.board',
        'conversations'
    ],
    [PERMISSIONS.SETTINGS_COMPANY]: [
        'company.setting.basic',
        'company.setting.photo',
        'company.setting.category-skill',
        'company.setting.activity-log',
        'company.setting.templates'
    ],
    [PERMISSIONS.SETTINGS_TEAM]: [
        'company.setting.user',
        'company.setting.user.add',
        'company.setting.user.update'
    ],
    [PERMISSIONS.SETTINGS_PIPELINE]: [
        'company.setting.pipeline'
    ],
    [PERMISSIONS.SETTINGS_EMAIL]: [
        'company.setting.email',
        'company.setting.email.smtp'
    ],
    [PERMISSIONS.SETTINGS_CANDIDATE_APPLY]: [
        'company.setting.apply-setting'
    ],
    [PERMISSIONS.SETTINGS_SCREENING]: [
        'company.question.group',
        'company.question.group.create',
        'company.question.group.edit'
    ],
    [PERMISSIONS.SETTINGS_QUIZ]: [
        'company.question.group',
        'company.question.group.create',
        'company.question.group.edit'
    ],
    [PERMISSIONS.SETTINGS_ASSESSMENT]: [
        'company.assessments',
        'company.assessments.create',
        'company.assessments.edit'
    ],
    [PERMISSIONS.SETTINGS_OTHER]: [
        'company.setting.custom-domain',
        'company.setting.app-key',
        'company.setting.integration',
        'company.setting.verification',
        'company.setting.user-app',
        'company.setting.ai-setup'
    ]
};

export function hasRoutePermission(routeName, permission) {
    let keyNames = [];

    for (let permissionKey in ROUTE_PERMISSIONS) {
        let hasRoute = ROUTE_PERMISSIONS[permissionKey].indexOf(routeName);
        if (hasRoute > -1) {
            keyNames.push(permissionKey);
        }
    }

    if (_.isEmpty(keyNames)) {
        return true;
    }

    let permissionQueue = [];
    for (let key = 0, len = keyNames.length; key < len; key++) {
        if (_.has(permission, keyNames[key]) && permission[keyNames[key]]) {
            permissionQueue.push(true);
        }
    }

    return !_.isEmpty(permissionQueue);
}

export function canViewJob() {
    return getPermissionRoute('job.view');
}

export function canManagementJob() {
    return getPermissionRoute('job.management');
}

export function canPublishJob() {
    return getPermissionRoute('job.publish');
}

export function canViewCandidate() {
    return getPermissionRoute('candidate.view');
}

export function canDeleteCandidate() {
    return getPermissionRoute('candidate.delete');
}

export function canOrganizeCandidate() {
    return getPermissionRoute('candidate.organize');
}

export function canCompanySettings() {
    return getPermissionRoute('settings.company');
}

export function canTeamSettings() {
    return getPermissionRoute('settings.team');
}

export function canPipelineSettings() {
    return getPermissionRoute('settings.pipeline');
}

export function canEmailSettings() {
    return getPermissionRoute('settings.email');
}

export function canApplyCandidateSettings() {
    return getPermissionRoute('settings.candidate.apply');
}

export function canScreeningSettings() {
    return getPermissionRoute('settings.screening');
}

export function canQuizSettings() {
    return getPermissionRoute('settings.quiz');
}

export function canAssessmentSettings() {
    return getPermissionRoute('settings.assessment');
}

export function canOtherSettings() {
    return getPermissionRoute('settings.other');
}

export function getPermissionRoute(permissionName) {
    let permission = getPermission();
    if (_.has(permission, permissionName) && permission[permissionName]) {
        return permission[permissionName];
    }

    return false;
}

export function getPermission() {
    return store.state.company?.permission || {};
}

