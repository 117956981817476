import Cookie from 'js-cookie';
import { previousRequestQueue } from "../app/api/Client";
import client from "../app/api/Client";

export default {
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    showValidateError(errorObject) {

        let errorMessage = '';

        if (errorObject instanceof Object) {
            let errorArray = Object.values(errorObject);
            errorArray.forEach(function (element) {
                errorMessage += element + '\n';
            });
        } else {
            errorMessage = errorObject;
        }
        return errorMessage;
    },

    setCookie(name, value, ttl, attr) {
        //ttl is in days
        if (typeof ttl === 'undefined') {
            ttl = 30;
        }

        attr = attr || {};

        return Cookie.set(name, value, {
            ...attr,
            expires: ttl
        });
    },

    getCookie(name) {
        return Cookie.get(name);
    },

    removeCookie(name) {
        return Cookie.remove(name);
    },

    getCompanyId() {
        let companyId = null;
        const storeData = JSON.parse(localStorage.getItem('vuex'));
        if (storeData instanceof Object) {
            let company = storeData.company || null;
            if (company !== null) {
                companyId = company.id;
            }
        }
        return companyId !== null ? companyId : "";
    },

    copyText(text) {
        let newElement = document.createElement('textarea');
        newElement.value = text;
        document.body.appendChild(newElement);
        newElement.select();
        document.execCommand('copy');
        document.body.removeChild(newElement);
        return true;
    },

    serializeValidationMessage(messages) {
        return _.mapValues(messages, msg => _.last(msg));
    },

    notValidInteger(value) {
        let strValue = ""+value;
        return !strValue.match(/^[0-9]*$/);
    }

}

export function middlewarePipeline (context, middleware, index) {
    const nextMiddleware = middleware[index];

    if(!nextMiddleware){
        return context.next;
    }

    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        );
        nextMiddleware({ ...context, next: nextPipeline });

    }
}

export function onStartingBeforeEachGuard(to, from, next) {
    if (previousRequestQueue) {
        previousRequestQueue.cancelAll();
    }

    if (to.matched.some(route => route.name === 'checkout')) {
        sessionStorage.intendedUrl = to.fullPath;
    }
}

const DUPLICATE_MIDDLEWARE = 900;

export function getRouteMiddleware(route, commonMiddleWares, DUPLICATE_MIDDLEWARE) {
    let tempo = [];
    return (((route.meta.middleware || [])
        .concat((route.matched[0].meta.middleware || [])) || [])
        .concat(commonMiddleWares) || [])
        .map((fn) => {
            if (tempo.indexOf(fn.name) > -1) {
                return DUPLICATE_MIDDLEWARE;
            }
            tempo.push(fn.name);
            return fn;
        }).filter(fn => fn !== DUPLICATE_MIDDLEWARE);
}

export function queryUrl(url, params) {
    return client().getUri({url, params});
}

export function stringConvertSpan(string) {
    let retString = "";
    if(string) {
        string.split(' ').map((world, i , arr) => {
            if (arr.length - 1 === i) {
                retString += '<span>' + world + '</span>';
            } else {
                retString += '<span>' + world + ' </span>';
            }
        });
    }
    return retString;
}

export function dateConvertSpan(date) {
    let retString = "";
    date.split(' ').map((word) => {
        if(word.indexOf(',') >= 0) {
            let data = word.replace(',', '');
            retString += '<span>' + data + '</span>, ';
        } else if(word.indexOf(':') >= 0) {
            let times = word.split(':').map((time, i , arr) => {
                if (arr.length - 1 === i) {
                    retString += '<span>' + time + ' </span>';
                } else {
                    retString += '<span>' + time + '</span>:';
                }
            });
            return times;
        } else {
            retString += "<span>" + word + " </span>";
        }
    });
    return retString;
}


export function stringLimit(value, size = 10) {
    if (!value) return '';

    value = value.toString();
    if (value.length <= size) {
        return value;
    }

    return value.substr(0, size) + '...';
}
