export const JOB = {
    EXAM_TYPES: {
        SCREENING: 1,
        QUIZ: 2,
        ASSESSMENT: 2,
    },

    QUESTION_TYPES: {
        SHORT_ANSWER: 1,
        MULTIPLE_CHOICE: 2
    },

    ERRORS: {
        INVALID_QUESTION_TITLE: "Please input question title within 250 characters.",
        INVALID_QUESTION_TYPE: "Please select a question type.",
        EMPTY_QUESTION_OPTIONS: "Please add one or more options for multiple choice",
        INVALID_QUESTION_OPTION: "Option can't be empty",
        INVALID_OPTION_ANSWER: "Please select a correct answer",
        INVALID_MULTIPLE_SAME_ANSWER: "Multiple options cannot have same answer.",
        INVALID_QUESTION_ALREADY_EXIT: "This question is already found in question ",
    },
    STATUS: {
        DRAFT: 1,
        PUBLISHED: 2,
        ARCHIVED: 3,
        DELETED: 4,
        REPUBLISHED: 10,
    }
};

export const SUBSCRIPTION = {
    PACKAGE : {
        PLAN : {
            "MONTHLY" : 1,
            "YEARLY" : 2,
            "LIFETIME" : 3,
            "DAILY" : 4,
            "WEEKLY" :  5
        }
    }
};

export const CANDIDATE_SORTING = {
    SORT_BY_EXPERIENCE : "Sort by experience",
    SORT_BY_SKILL_MATCH : "Sort by skill match",
    SORT_BY_EDUCATION_MATCH : "Sort by education match",
    SORT_BY_EXPERIENCE_MATCH : "Sort by experience match",
    SORT_BY_TOTAL_AI_SCORE : "Sort by AI score",
    SORT_BY_QUIZ_SCORE : "Sort by quiz score"
};

export const PIPELINE_CANDIDATE_SORTING = {
    SORT_BY_EXPERIENCE : "Sort by experience",
    SORT_BY_SKILL_MATCH : "Sort by skill match",
    SORT_BY_EDUCATION_MATCH : "Sort by education match",
    SORT_BY_EXPERIENCE_MATCH : "Sort by experience match",
    SORT_BY_TOTAL_AI_SCORE : "Sort by AI score",
    SORT_BY_QUIZ_SCORE : "Sort by quiz score",
    SORT_BY_CANDIDATE_NAME : "Sort by candidate name",
    SORT_BY_APPLICATION_DATE : "Sort by application date",
};

export const AI_SCORE_COLORS = {
    QUIZ : '#ff9635',
    SKILLS : '#2fc1e1',
    EDUCATION : '#597dfc',
    EXPERIENCE : '#60ce83',
    TOTAL : '#ff5f74',
};

export const JOB_APPLY_STATUS = {
    COMPLETE: 1,
    SELECTED: 5,
    REJECT: 9,
}

export const DEFAULT_PIPELINES_ID = {
    SELECTED: "selected",
    REJECT: "rejected",
    APPLIED : 0
}
export const INTEGRATIONS = {
    GOOGLE_JOB_POST : 'company.integration.job.google',
    NEUVOO_JOB_POST : 'company.integration.job.neuvoo'
};
