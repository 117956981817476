import {store} from "../store";
import pageTitle from "../../config/pageTitle";
import {isEmpty} from 'lodash';
import {getRouteMiddleware, middlewarePipeline, onStartingBeforeEachGuard} from "../../extra/helper";
import routePermission from "./routePermission";
import affiliateCookie from "./affiliateCookie";
import router from "../router";


const commonMiddleWares = [
    routePermission,
    affiliateCookie
];

export function beforeEachGuard(to, from, next) {
    router.app.$Progress.start();
    onStartingBeforeEachGuard(to, from, next);

    let middleware = getRouteMiddleware(to, commonMiddleWares);

    if (isEmpty(middleware)) {
        return next();
    }

    const context = {
        to,
        from,
        next,
        store,
        router
    };

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
}

export function afterEachGuard(to, from) {
    const routeName = to.name;
    document.title = pageTitle[routeName] || "easy.jobs - Easy Solution For The Job Recruitment";
    router.app.$Progress.finish();
}

export function execMiddleware(routerInstance) {
    routerInstance.beforeEach((to, from, next) => beforeEachGuard(to, from, next));
    routerInstance.afterEach((to, from) => afterEachGuard(to, from));
    return routerInstance;
}
