import client from "./Client";

export function getCategory(searchQuery) {
    return client().get(`/job/category`, {params: {keyword: searchQuery}}).then(({data}) => data);
}

export function getCountry() {
    return client().get(`/country`).then(({data}) => data);
}

export function getState(countryId) {
    return client().get(`/state/${countryId}`).then(({data}) => data);
}

export function getCity(countryId, stateId) {
    return client().get(`/city/${countryId}/${stateId}`).then(({data}) => data);
}

export function getSkill(searchQuery) {
    return client().get(`/job/skill`, {params: {keyword: searchQuery}}).then(({data}) => data);
}

export function getJobCreateMetaData() {
    return client().get(`/job/information-meta-data`).then(({data}) => data);
}

export function getJobScreeningMetaData() {
    return client().get(`/job/screening-meta-data`).then(({data}) => data);
}

export function getJobQuizMetaData() {
    return client().get(`/job/quiz-meta-data`).then(({data}) => data);
}

export function getRequiredFieldData(jobId) {
    return client().get(`/job/${jobId}/required-fields`).then(({data}) => data);
}

export function getJobBasicData(jobId) {
    return client().get(`/job/${jobId}/basic`).then(({data}) => data);
}

export function getJobScreeningData(jobId) {
    return client().get(`/job/${jobId}/screening`).then(({data}) => data);
}

export function getJobQuizData(jobId) {
    return client().get(`/job/${jobId}/quiz`).then(({data}) => data);
}

export function getQuestionSet(id) {
    return client().get(`/question-set/${id}`).then(({data}) => data);
}

export function getCountryPhoneCode() {
    return client().get(`/company/country-phonecode`).then(({data}) => data);
}

export function getCompanyTypes(id) {
    return client().get(`/company/company-types`).then(({data}) => data);
}

export function getCandidateInfo() {
    return client().get(`/candidate`).then(({data}) => data);
}

export function getCandidateEducation(id = null) {
    return client().get(`/candidate/education`, {params: {id: id}}).then(({data}) => data);
}

export function getEducationLevel(keyword='') {
    return client().get(`/education-level?keyword=${keyword}`).then(({data}) => data);
}

export function getEducationDegree(id = null) {
    if (id) {
        return client().get(`/degree/${id}`).then(({data}) => data);

    }
    return client().get(`/degree`).then(({data}) => data);

}
export function searchEducationDegree(keyword = '') {
    return client().get(`/degree?keyword=${keyword}`).then(({data}) => data);

}

export function getCandidateEmployment(id = null) {
    return client().get(`/candidate/employment`, {params: {id: id}}).then(({data}) => data);
}

export function getCandidateCompany(keyword = '') {
    return client().get(`/company?keyword=${keyword}`).then(({data}) => data);
}

export function getCandidateDepartments(keyword='') {
    return client().get(`/department?keyword=${keyword}`).then(({data}) => data);
}

export function getCandidateDesignation(keyword='') {
    return client().get(`/designation?keyword=${keyword}`).then(({data}) => data);
}

export function getNationalities() {
    return client().get(`/nationality`).then(({data}) => data);
}

export function getQuizMetaData() {
    return client().get(`/job/quiz-meta-data`).then(({data}) => data);
}

export function getApplicantExists(applicant) {
    return client().get(`job/applicant/${applicant}/exists`).then(({data}) => data);
}

export function getDepartments(searchQuery) {
    return client().get(`/department`, {params: {keyword: searchQuery}}).then(({data}) => data);
}

export function getCompanyConversationNotification() {
    return client().get(`conversation/notifications`).then(({data}) => data);
}

export function getCandidateConversationNotification() {
    return client().get(`candidate/conversation/notifications`).then(({data}) => data);
}


export function getCompanyHasConversationApplicantList(params) {
    return client().get(`conversation/applicants`, {params}).then(({data}) => data);
}

export function getCandidateHasConversationApplicantList(params) {
    return client().get(`candidate/conversation`, {params}).then(({data}) => data);;
}
