<template>
    <div id="appa">
      <router-view/>
      <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
    export default {
        mounted () {
        },
        created () {

        }
    }
</script>
