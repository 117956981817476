import Vue from "vue";
import client from "../api/Client";
import {USER_LOGGED_IN} from "../../constants/action-type";

export default function redirectToDashboard({to, from, next, store, router}) {
    if (to.query.params) {
        client().post('redirect-to-dashboard', to.query)
            .then(({data: {data: {user, redirect_path}}}) => {
                store.dispatch(USER_LOGGED_IN, user);
                if (redirect_path) {
                    return router.push(redirect_path);
                }
            })
            .catch(({response}) => {
                Vue.$toast.error("Invalid params.");
            });
    }
    return router.push({name: 'login'});
}
