import {
    SET_STATE,
    RESET_STATE,
    SET_USER,
    RESET_USER,
    SET_LANGUAGE,
    SET_NOTIFICATION,
    REMOVE_COMPANY_FROM_USER,
    SET_ALERT,
    SET_AUTH_TOKEN,
    CHANGE_COMPANY,
    ADD_COMPANY_TO_USER,
    SET_USER_DATA,
    UPDATE_USER_COMPANY,
    SET_STATE_VERSION,
    SET_TOGGLE_SIDEBAR,
    SET_EXPIRED_PACKAGE,
    SET_PAGE_STATE,
    SET_SORTING_PIPELINE_CANDIDATE,
    SET_CANDIDATE_PIPELINE_ORDER,
    CHANGE_COMPANY_PERMISSION,
    SET_LAST_SEEN_VERSION,
    UPDATE_MESSAGE_NOTIFICATION
} from '../../constants/mutations-types'

export default {
    [SET_ALERT](state, data) {
        state.alert = {
            ...state.alert,
            ...data
        }
    },
    [SET_USER_DATA](state, data) {
        state.user = {
            ...state.user,
            ...data
        }
    },

    [SET_USER](state, data) {
        state.user = data;
    },

    [RESET_USER](state) {
        state.user = null;
        state.token = null;
        state.company = null;
        state.notifications = [];
        state.isExpired = false;
    },

    [SET_AUTH_TOKEN](state, token) {
        state.token = token;
    },

    [SET_STATE](state, data) {
        Object.assign(state, data);
    },

    [RESET_STATE](state) {
        state = {}
    },

    [SET_LANGUAGE](state, lang) {
        state.language = lang
    },

    [SET_NOTIFICATION](state, notifications) {
        state.notifications = notifications;
    },

    [CHANGE_COMPANY](state, value) {
        state.company = value;
        if (!_.isNull(state.company)) {
            state.user.package_rules = state.company.package_rule;
        }
    },

    [CHANGE_COMPANY_PERMISSION](state, permission) {
        state.company.permission = permission;
    },

    [ADD_COMPANY_TO_USER](state, company) {
        state.user.companies.push(company);
    },

    [REMOVE_COMPANY_FROM_USER](state, company) {
        state.user.companies = state.user.companies.filter(c => c.id != company.id);
    },

    [UPDATE_USER_COMPANY](state, company) {
        state.user.companies.map((c, index) => {
            if (c.id === company.id) {
                state.user.companies[index] = company;
            }
        });
    },

    [SET_STATE_VERSION](state, data) {
        state.version = data;
    },

    [SET_TOGGLE_SIDEBAR](state, data) {
        state.showSidebar = !state.showSidebar;
    },

    [SET_EXPIRED_PACKAGE](state, isExpired) {
        state.isExpired = isExpired || false;
    },

    [SET_PAGE_STATE](state, data) {
        state.pageState = {
            ...state.pageState,
            ...data
        };
    },

    [SET_SORTING_PIPELINE_CANDIDATE](state) {
        _.map(state.pageState.pipelines, (pipeline) => {

            switch (state.pageState.sortBy) {
                case "SORT_BY_EXPERIENCE":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.total_experience ?? 0;
                        let applicantBScore = applicantB.total_experience ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_SKILL_MATCH":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.skills_score ?? 0;
                        let applicantBScore = applicantB.skills_score ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_EDUCATION_MATCH":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.education_score ?? 0;
                        let applicantBScore = applicantB.education_score ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_EXPERIENCE_MATCH":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.experience_score ?? 0;
                        let applicantBScore = applicantB.experience_score ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_TOTAL_AI_SCORE":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.final_ai_score ?? 0;
                        let applicantBScore = applicantB.final_ai_score ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_QUIZ_SCORE":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let applicantAScore = applicantA.scores.quiz ?? 0;
                        let applicantBScore = applicantB.scores.quiz ?? 0;
                        return applicantBScore - applicantAScore || applicantA.id.toString().localeCompare(applicantB.id);
                    });
                    break;
                case "SORT_BY_CANDIDATE_NAME":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        let x = applicantA.user.name.toLowerCase();
                        let y = applicantB.user.name.toLowerCase();
                        if (x < y) {
                            return -1;
                        }
                        if (x > y) {
                            return 1;
                        }
                        return 0;
                    });
                    break;
                case "SORT_BY_APPLICATION_DATE":
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        return applicantA.submitted_at_timestamp - applicantB.submitted_at_timestamp;
                    });
                    break;

                default:
                    pipeline.applicants.sort((applicantA, applicantB) => {
                        return applicantA.pipeline_order - applicantB.pipeline_order || applicantA.id.toString().localeCompare(applicantB.id);
                    });
            }
            return pipeline;

        });
    },

    [SET_CANDIDATE_PIPELINE_ORDER](state) {
        state.pageState.pipelines.map((pipeline) => {
            pipeline.applicants.map((applicant) => {
                let candidatePipelineOrder = state.pageState.candidatePipelineOrders[applicant.id];
                if(typeof(candidatePipelineOrder) != 'undefined') {
                    applicant.pipeline_order = candidatePipelineOrder;
                }
                return applicant;
            })
            return pipeline;
        })
    },

    [SET_LAST_SEEN_VERSION](state, data) {
        state.user.last_seen_version = data;
    },

    [UPDATE_MESSAGE_NOTIFICATION](state, data) {
        state.messageNotification = {
            ...state.messageNotification,
            ...{unseenMessage: 0, notifyStatus: 0, latestJobId: null, jobTitle: ''},
            ...data
        };
    }

}
