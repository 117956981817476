export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const GET_USER_DATA = "GET_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const LOAD_NOTIFICATION = "LOAD_NOTIFICATION";
export const LOAD_ALERT = "LOAD_ALERT";
export const SWITCH_TO_ACCOUNT = "SWITCH_TO_ACCOUNT";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const CREATED_A_NEW_COMPANY = "CREATED_A_NEW_COMPANY";
export const COMPANY_ADDED = "COMPANY_ADDED";
export const COMPANY_REMOVED = "COMPANY_REMOVED";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const RESET_EXPIRED_PACKAGE = "RESET_EXPIRED_PACKAGE";
export const UPDATE_PAGE_STATE = "UPDATE_PAGE_STATE";
export const SORTING_PIPELINE_CANDIDATE = "SORTING_PIPELINE_CANDIDATE";
export const UPDATE_CANDIDATE_PIPELINE_ORDER = "UPDATE_CANDIDATE_PIPELINE_ORDER";
export const UPDATE_COMPANY_PERMISSION = "UPDATE_COMPANY_PERMISSION";
export const UPDATE_LAST_SEEN_VERSION = "UPDATE_LAST_SEEN_VERSION";
export const LOAD_MESSAGE_NOTIFICATION = "LOAD_MESSAGE_NOTIFICATION";
export const LOAD_CONVERSATION_CANDIDATES = "LOAD_CONVERSATION_CANDIDATES";
