import Vue from 'vue';

Vue.filter('formatDate', function (value) {
    if (!value) return '';
    let date = new Date(value);
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear();
    let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return  ('0' + day).slice(-2) + " " + months[month] + " " + year;
});
