export default {
    mode : {
        CANDIDATE : 'candidate',
        COMPANY : 'company'
    },

    lang : [
        {
            image : '/img/languages/004-united-states-of-america.svg',
            name : 'English',
            code : 'en',
            extra: ''
        },
        {
            image : '/img/languages/008-bangladesh.svg',
            name : 'Bangla',
            code : 'bn',
            extra: ''
        },
        {
            image : '/img/languages/007-germany.svg',
            name : 'German',
            code : 'de',
            extra: ''
        },
        {
            image : '/img/languages/001-spain.svg',
            name : 'Spanish',
            code : 'es',
            extra: ''
        },
        {
            image : '/img/languages/002-italy.svg',
            name : 'Italian',
            code : 'it',
            extra: ''
        },
        {
            image : '/img/languages/003-norway.svg',
            name : 'Norwegian',
            code : 'no',
            extra: ''
        },
        {
            image : '/img/languages/009-france.svg',
            name : 'French',
            code : 'fr',
            extra: ''
        },
        {
            image : '/img/languages/india.svg',
            name : 'Hindi',
            code : 'hi',
            extra: ''
        },
        {
            image : '/img/languages/010-russia.svg',
            name : 'Russian',
            code : 'ru',
            extra: ''
        },
        {
            image : '/img/languages/portugal.svg',
            name : 'Portuguese',
            code : 'pt',
            extra: ''
        },
        {
            image : '/img/languages/011-japan.svg',
            name : 'Japanese',
            code : 'ja',
            extra: ''
        },
        {
            image : '/img/languages/netherlands.svg',
            name : 'Dutch',
            code : 'nl',
            extra: ''
        },
        {
            image : '/img/languages/china.svg',
            name : 'Chinese',
            code : "tc",
            extra: '(Traditional)'
        },
        {
            image : '/img/languages/china.svg',
            name : 'Chinese',
            code : "sc",
            extra: '(Simplified)'
        },



    ],

    STRIPE: {
        DEV: {
            KEY: 'pk_test_Ugxh4wFIHmBGW84BnijEHYOj00jsZ93gTy',
            SECRET: 'sk_test_icYKDsk7btZHCmB7SNcCl37T00jUwbRdmd',
            WH_SECRET: 'whsec_3iW9MjgkYMcWV5sCMEcpPydFETpz4eyo',
            PRODUCT_ID: 'prod_GUpgbYRrba1Mgz',
        }
    }
}

export const ENV = process.env;

