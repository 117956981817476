export const SET_USER = "SET_USER";
export const SET_USER_DATA = "SET_USER_DATA";
export const RESET_USER = "RESET_USER";
export const RESET_STATE = "RESET_STATE";
export const SET_STATE = "SET_STATE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_ALERT = "SET_ALERT";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const CHANGE_COMPANY = "CHANGE_COMPANY";
export const ADD_COMPANY_TO_USER = "ADD_COMPANY_TO_USER";
export const REMOVE_COMPANY_FROM_USER = "REMOVE_COMPANY_FROM_USER";
export const UPDATE_USER_COMPANY = "UPDATE_USER_COMPANY";
export const SET_STATE_VERSION = "SET_STATE_VERSION";
export const SET_TOGGLE_SIDEBAR = "SET_TOGGLE_SIDEBAR";
export const SET_EXPIRED_PACKAGE = "SET_EXPIRED_PACKAGE";
export const SET_PAGE_STATE = "SET_PAGE_STATE";
export const SET_SORTING_PIPELINE_CANDIDATE = "SET_SORTING_PIPELINE_CANDIDATE";
export const SET_CANDIDATE_PIPELINE_ORDER = "SET_CANDIDATE_PIPELINE_ORDER";
export const CHANGE_COMPANY_PERMISSION = "CHANGE_COMPANY_PERMISSION";
export const SET_LAST_SEEN_VERSION = "SET_LAST_SEEN_VERSION";
export const UPDATE_MESSAGE_NOTIFICATION = "UPDATE_MESSAGE_NOTIFICATION";
