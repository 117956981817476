import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export const store = new Vuex.Store({
    state: {
        user: null,
        token: null,
        expiry: null,
        language: 'en',
        company: null,
        notifications: [],
        messageNotification: {},
        alert: {
            type: 'danger',
            message: "something went wrong!",
            isShow: false
        },
        isExpired: false,
        version: 1,
        showSidebar: false,
        pageState : {},

    },
    actions,
    mutations,
    getters,
    plugins: [vuexLocal.plugin]
});
