export const SWITCHED_TO_ANOTHER_ACCOUNT = "SWITCHED_TO_ANOTHER_ACCOUNT";
export const SHOW_CANDIDATE_DETAILS = "SHOW_CANDIDATE_DETAILS";
export const HIDE_CANDIDATE_DETAILS = "HIDE_CANDIDATE_DETAILS";
export const CANDIDATE_RATING_ADDED = "CANDIDATE_RATING_ADDED";
export const CANDIDATE_PIPELINE_CHANGED = "CANDIDATE_PIPELINE_CHANGED";
export const CHECK_AND_PUBLISH_JOB = "CHECK_AND_PUBLISH_JOB";
export const UPDATE_JOB_INFO = "UPDATE_JOB_INFO";
export const OPEN_JOB_SHARE_MODAL = "OPEN_JOB_SHARE_MODAL";
export const RELOAD_CANDIDATE_PROFILE = "RELOAD_CANDIDATE_PROFILE";
export const PIPELINE_UPDATED = "PIPELINE_UPDATED";
export const NOTIFICATION_CLEARED = "NOTIFICATION_CLEARED";
export const SHOW_NOTIFICATION_SETTINGS = "SHOW_NOTIFICATION_SETTINGS";
export const SWITCHED_TO_ANOTHER_LANGUAGE = "SWITCHED_TO_ANOTHER_LANGUAGE";
export const CHECK_RELEASE_NOTIFICATION = "CHECK_RELEASE_NOTIFICATION";

export const CONVERSATION_SIDEBAR_TOGGLE = "CONVERSATION_SIDEBAR_TOGGLE";

export const SHOW_JOB_TEMP_PREVIEW = "SHOW_JOB_TEMP_PREVIEW";
export const PACKAGE_LIMIT_EXCEEDED = "PACKAGE_LIMIT_EXCEEDED";
