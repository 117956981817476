import axios from 'axios';
import help from '../../extra/helper';
import app from '../../main';
import {store} from "../store";
import {RESPONSE_CODE} from "@/constants/enums";
import router from "../router";
import {EventBus} from "@/extra/event-bus";
import {PACKAGE_LIMIT_EXCEEDED} from "@/constants/events";
import {ENV} from "@/config/app";

// const baseURL = process.env.MIX_SPA_API_URL || 'https://app.easyjobs.test/api/v2';
const baseURL = ENV.VUE_APP_API_URL || 'https://app.easyjobs.test/api/v2';

const token = () => help.getCookie('ej_token');


const getCompanyId = () => {
    let companyId = null;
    let company = store.state.company || null;

    if (company !== null) {
        companyId = company.id;
    }

    return companyId !== null ? companyId : "";
};

export const previousRequestQueue = {

    requests: [],

    add(data) {
        this.requests.push(data)
    },

    clear() {
        this.requests = [];
    },

    cancelAll() {

        if (this.requests.length > 0) {

            this.requests.map((cancel) => {
                cancel("Request cancelled");
            });

            this.clear();
        }
    }
};

export const request = axios.create({
    baseURL
});

request.interceptors.request.use((config) => {

    app.$Progress.start();

    config.headers = {
        "Accept": 'application/json',
        "Authorization": `Bearer ${token()}`,
        "Company-Id": getCompanyId(),
        'State-Version': store.state.version,
        'Lang' : store.state.language || 'en'
    };

    config.cancelToken = new axios.CancelToken((e) => {
        previousRequestQueue.add(e);
    });

    return config;
});

request.interceptors.response.use((resp) => {
    app.$Progress.finish();
    return resp;
}, (error) => {
    if (typeof error.response == 'object') {
        if (error.response.status === RESPONSE_CODE.PRECONDITION_FAILED) {
            request.get('/user').then(({data}) => {
                app["USER_LOGGED_IN"](data.data);
                app.switchToCompany(data.data);
            } ).finally(()=>{
                window.location.reload()
            });
        } else if (error.response.status === RESPONSE_CODE.PAYMENT_REQUIRED) {
            store.state.isExpired = true;
            return router.push({name: 'my-account.payment-history'});
        } else if (error.response.status === RESPONSE_CODE.UNAUTHORISED_ACCESS) {
            app.$toast.error(app.$t("Sorry you don't have permission for this action."));
            return router.push({name: 'dashboard'});
        } else if (error.response.status === RESPONSE_CODE.LOGIN_REQUIRED) {
            return router.push({name: 'logout'});
        } else if (error.response.status === RESPONSE_CODE.APPLICANT_DELETED) {
            app.$toast.error(app.$t("Applicant not found."));
            return router.push({name: 'dashboard'});
        }else if (error.response.status === RESPONSE_CODE.PACKAGE_LIMIT_EXCEEDED) {
            //Fire an event to show the package limit exceeded modal/dialog
            EventBus.$emit(PACKAGE_LIMIT_EXCEEDED);
            return Promise.reject(error);
        }
        else if (error.response.status === RESPONSE_CODE.CONFLICT_RELOAD) {
            app.$toast.error(app.$t(error.response.data.message));
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    } else {
        return Promise.reject(error);
    }
});


export default function makeClient() {
    return request;
}
