import { isNull } from 'lodash';
import routePermission from "./routePermission";

export default function auth({next, store, router, to}) {

    let token = _helper.getCookie('ej_token') || null;

    if(isNull(token) || isNull(store.state.user)){
        let query = to.query;
        return router.push({
            name: 'login',
            query: query
        });
    }

    return next();
}
