export default {
    "home" : "easy.jobs - Easy Solution For The Job Recruitment",
    "login" : "easy.jobs | Login",
    "registration" : "easy.jobs | Registration",
    "dashboard" : "easy.jobs | Dashboard",
    "notification" : "easy.jobs | Notifications",
    "checkout" : "easy.jobs | Checkout",
    'invitation.login' : "easy.jobs | On-board",
    'password.forget' : "easy.jobs | Forget Password",
    'password.reset' : "easy.jobs | Forget Reset",
    'email.verify' : "easy.jobs | Email verification",
    'company.create' : "easy.jobs | Create Company",
    'company.candidates' : "easy.jobs | Candidates",
    'jobs.published' : "easy.jobs | Published Jobs",
    'jobs.draft' : "easy.jobs | Draft Jobs",
    'jobs.archived' : "easy.jobs | Archived Jobs",
    'job.candidates' : "easy.jobs | Job Candidates",
    'job.pipeline' : "easy.jobs | Job Pipelines",
    'job.create' : "easy.jobs | Job Create",
    'job.edit' : "easy.jobs | Edit Job",
    'job.screening' : "easy.jobs | Job Screening Question",
    'job.quiz' : "easy.jobs | Job Quiz",
    'company.setting.basic' : "easy.jobs | Basic Information",
    'company.setting.photo' : "easy.jobs | Photos & Colors",
    'company.setting.user' : "easy.jobs |  Manage Users",
    'company.setting.app-key' : "easy.jobs | App Key",
    'company.setting.custom-domain' : "easy.jobs | Custom Domain",
    'company.setting.pipeline' : "easy.jobs | Pipeline Setup",
    'company.setting.user-app' : "easy.jobs | Integrations",
    'company.setting.integration' : "easy.jobs | Integrations",
    'company.setting.ai-setup' : "easy.jobs |  AI Setup",
    'company.setting.email' : "easy.jobs | Email Setup",
    'company.setting.email.smtp' : "easy.jobs | SMTP Setup",
    'company.setting.verification' : "easy.jobs | Verification",
    'company.setting.category-skill' : "easy.jobs | Categories & Skills",
    'company.setting.apply-setting' : "easy.jobs | Candidate Apply Setting",
    'company.setting.activity-log' : "easy.jobs | Activity Log",
    'company.setting.templates' : "easy.jobs | Templates",
    'company.question.group' : "easy.jobs | Question Set",
    'company.question.group.create' : "easy.jobs | Create Question Set",
    'company.question.edit' : "easy.jobs | Edit Question Set",
    'company.assessments' : "easy.jobs | Assessments",
    'company.assessments.create' : "easy.jobs | Create Assessment",
    'company.assessments.edit' : "easy.jobs | Edit Assessment",
    'my-account' : "easy.jobs | Subscription",
    'my-account.billing' : "easy.jobs | Billing",
    'my-account.change-password' : "easy.jobs | Change Password",
    'my-account.payment-history' : "easy.jobs | Payment History",
    'candidate.basic-info' : "easy.jobs | Profile",
    'candidate.basic-info.edit' : "easy.jobs | Update Profile",
    'candidate.qualifications' : "easy.jobs | Education",
    'candidate.qualifications.create' : "easy.jobs | Add Education",
    'candidate.qualifications.edit' : "easy.jobs | Update Education",
    'candidate.employments' : "easy.jobs | Employment",
    'candidate.employments.create' : "easy.jobs | Add Employment",
    'candidate.employments.edit' : "easy.jobs | Update Employment",
    'candidate.resume' : "easy.jobs | Resume",
    'candidate.applied-jobs' : "easy.jobs | Applied Jobs",
    'candidate.unfinished-jobs' : "easy.jobs | Unfinished Jobs",
    'candidate.messages' : "easy.jobs | Messages",
    'candidate.message-details' : "easy.jobs | Message Details",
    'error.not-found' : "easy.jobs | Page not found",
    'applicant.details':"easy.jobs | Applicant Details",
    'company.setting.notifications':"easy.jobs | Notification Settings"
}
