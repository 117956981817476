import {
    SET_USER,
    SET_LANGUAGE,
    SET_NOTIFICATION,
    SET_ALERT,
    SET_AUTH_TOKEN,
    RESET_USER,
    CHANGE_COMPANY,
    SET_USER_DATA,
    ADD_COMPANY_TO_USER,
    UPDATE_USER_COMPANY,
    REMOVE_COMPANY_FROM_USER,
    SET_STATE_VERSION,
    SET_TOGGLE_SIDEBAR,
    SET_EXPIRED_PACKAGE,
    SET_PAGE_STATE,
    SET_SORTING_PIPELINE_CANDIDATE,
    SET_CANDIDATE_PIPELINE_ORDER,
    CHANGE_COMPANY_PERMISSION,
    SET_LAST_SEEN_VERSION,
    UPDATE_MESSAGE_NOTIFICATION,
} from '../../constants/mutations-types'

import {
    USER_LOGGED_IN, CHANGE_LANGUAGE, LOAD_NOTIFICATION, LOAD_ALERT, USER_LOGGED_OUT, EMAIL_VERIFIED,
    SWITCH_TO_ACCOUNT, UPDATE_USER_DATA, CREATED_A_NEW_COMPANY, UPDATE_COMPANY_DATA, COMPANY_ADDED,
    COMPANY_REMOVED, TOGGLE_SIDEBAR, RESET_EXPIRED_PACKAGE, UPDATE_PAGE_STATE, SORTING_PIPELINE_CANDIDATE,
    UPDATE_CANDIDATE_PIPELINE_ORDER, UPDATE_COMPANY_PERMISSION, UPDATE_LAST_SEEN_VERSION,
    LOAD_MESSAGE_NOTIFICATION, LOAD_CONVERSATION_CANDIDATES
} from '../../constants/action-type'

import {
    getCompanyConversationNotification,
    getCandidateConversationNotification,
    getCompanyHasConversationApplicantList, getCandidateHasConversationApplicantList
} from '../api/CommonRequest'

import {first} from "lodash";
import router from '../router'

export default {
    [USER_LOGGED_IN]({ commit }, data) {
        let token = data.token;
        _helper.setCookie('ej_token', token, 100);
        delete data.token;

        let stateVersion = data.state_version;
        commit(SET_STATE_VERSION, stateVersion);
        delete data.state_version;

        commit(SET_USER, data);
        commit(SET_AUTH_TOKEN, token);
    },

    [USER_LOGGED_OUT]({ commit }) {
        commit(RESET_USER);
        _helper.removeCookie('ej_token');
    },

    [EMAIL_VERIFIED]({ commit }) {
        commit(SET_USER_DATA, {
            email_verified_at: true
        });
    },

    [CHANGE_LANGUAGE]({ commit }, lang) {
        commit(SET_LANGUAGE, lang);
    },

    [LOAD_NOTIFICATION]({ commit }, notifications) {
        commit(SET_NOTIFICATION, notifications);
    },

    [LOAD_ALERT]({ commit }, data) {
        commit(SET_ALERT, data);
    },

    [SWITCH_TO_ACCOUNT]({ commit }, val) {
        commit(CHANGE_COMPANY, val);
    },

    [UPDATE_COMPANY_DATA]({ commit }, val) {
        commit(CHANGE_COMPANY, val);
        commit(UPDATE_USER_COMPANY, val);
    },

    [UPDATE_COMPANY_PERMISSION]({ commit }, val) {
        commit(CHANGE_COMPANY_PERMISSION, val);
    },

    [UPDATE_USER_DATA]({ commit }, data) {
        let stateVersion = data.state_version;
        commit(SET_STATE_VERSION, stateVersion);
        delete data.state_version;

        commit(SET_USER, data);
    },

    [CREATED_A_NEW_COMPANY]({ commit, dispatch }, company) {
        // commit(ADD_COMPANY_TO_USER, company);
        dispatch(SWITCH_TO_ACCOUNT, company);
    },

    [COMPANY_ADDED]({ commit }, company) {
        commit(ADD_COMPANY_TO_USER, company);
    },

    [COMPANY_REMOVED]({ commit }, company) {
        commit(REMOVE_COMPANY_FROM_USER, company);
    },

    [TOGGLE_SIDEBAR]({ commit }, data) {
        commit(SET_TOGGLE_SIDEBAR);
    },

    [RESET_EXPIRED_PACKAGE]({ commit }, data) {
        commit(SET_EXPIRED_PACKAGE, data);
    },

    [RESET_EXPIRED_PACKAGE]({ commit }, data) {
        commit(SET_EXPIRED_PACKAGE, data);
    },

    [UPDATE_PAGE_STATE]({ commit }, data) {
        commit(SET_PAGE_STATE, data);
    },

    [SORTING_PIPELINE_CANDIDATE]({ commit }, data) {
        commit(SET_SORTING_PIPELINE_CANDIDATE);
    },

    [UPDATE_CANDIDATE_PIPELINE_ORDER]({ commit }, data) {
        commit(SET_CANDIDATE_PIPELINE_ORDER);
    },

    [UPDATE_LAST_SEEN_VERSION]({ commit }, data) {
        commit(SET_LAST_SEEN_VERSION, data);
    },


    [LOAD_MESSAGE_NOTIFICATION]({ commit, state }) {

        if (state.company) {
            getCompanyConversationNotification().then(({data}) => {
                commit(UPDATE_MESSAGE_NOTIFICATION, data);
            });
        } else {
            getCandidateConversationNotification().then(({data}) => {
                commit(UPDATE_MESSAGE_NOTIFICATION, data);
            });
        }

    },

    async [LOAD_CONVERSATION_CANDIDATES]({commit, state}, queryParams) {

        let {params, appendCandidate = false} = queryParams;

        let data;
        if (state.company) {
            ({data} = await getCompanyHasConversationApplicantList(params));
        } else {
            ({data} = await getCandidateHasConversationApplicantList(params));
        }

        let applicantId = router.currentRoute.params?.id ?? null;
        if(!applicantId) {
            if(data.data.length > 0) {
                applicantId = first(data.data).id;
            } else {
                applicantId = state.pageState.applicantId;
            }
        }

        let candidates = [];
        if(appendCandidate) {
            candidates = state.pageState.candidates;
        }
        candidates.push(...data.data);

        commit(SET_PAGE_STATE, {candidates, applicantId, candidatePage: data.current_page + 1, candidateLastPage: data.last_page, totalCandidate: data.total});
    }
}
