import { isNull } from 'lodash'

export default function guest({next, store, router}) {

    if(!isNull(store.state.user)){
        return router.push({
            name: 'dashboard'
        });
    }

    return next();
}
