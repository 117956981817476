import Alert from '../components/common/Alert';
import {mapActions} from 'vuex';
import {LOAD_ALERT, SWITCH_TO_ACCOUNT, LOAD_MESSAGE_NOTIFICATION} from '../constants/action-type';
import client from "../app/api/Client";
import {JOB} from "./constants";
import {EventBus} from "./event-bus";
import {OPEN_JOB_SHARE_MODAL} from "../constants/events";
import moment from "moment";
import VueScrollTo from "vue-scrollto";

export default {
    components: {Alert},

    computed: {
    },

    methods: {
        ...mapActions([LOAD_ALERT, SWITCH_TO_ACCOUNT, LOAD_MESSAGE_NOTIFICATION]),
        showAlert(type, message) {
            this[LOAD_ALERT]({
                type,
                message,
                isShow: true
            });
        },

        publishJob(job) {
            try {
                let message = {
                    title: this.$t("Confirmation"),
                    body: this.$t(`Are you sure you want to publish this job?`)
                };

                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };

                this.$dialog.confirm(message, dialogConfig).then(() => {
                    client().post(`/job/${job.slug}/change-status`, {
                        status: JOB.STATUS.PUBLISHED
                    }).then(({data}) => {
                        if (data.status === 'SUCCESS') {
                            this.$router.push({name: 'job.edit', params: {id: job.slug}});
                            EventBus.$emit(OPEN_JOB_SHARE_MODAL, job);
                        } else {
                            this.$toast.error(data.message);
                        }
                    }).catch(err => {
                        if (err.response.status === 422) {
                            this.errors = err.response.data.message;
                            this.$toast.error('Oops! Please check your input');
                        } else if (err.response.status === 490) {

                        }else {
                            this.$toast.error(err.response.data.message);
                        }
                    }).finally(() => {
                        this[LOAD_MESSAGE_NOTIFICATION]();
                    });
                });
            } catch (e) {
            }
        },

        archiveJob(jobId) {
            try {
                let message = {
                    title: this.$t("Confirmation"),
                    body: this.$t("Are you sure you want to archive this job?")
                };

                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };

                this.$dialog.confirm(message, dialogConfig).then(() => {
                    client().post(`/job/${jobId}/change-status`, {
                        status: JOB.STATUS.ARCHIVED
                    }).then(({data}) => {
                        if (data.status === 'SUCCESS') {
                            this[LOAD_MESSAGE_NOTIFICATION]();
                            this.$toast.success(data.message);
                            this.$router.push({name: 'jobs.archived'});
                        } else {
                            this.$toast.error(data.message);
                        }
                    }).catch(err => {
                        if (err.response.status === 422) {
                            this.errors = err.response.data.message;
                            this.$toast.error('Oops! Please check your input');
                        } else {
                            this.$toast.error(err.response.data.message);
                        }
                    });
                });
            } catch (e) {
            }
        },

        pushErrorMessage(array, message) {
            if (array.indexOf(message) < 0) {
                array.push(message);
            }
        },

        removeErrorMessage(array, message) {
            _.remove(array, (msg) => {
                return msg == message;
            })
        },

        switchToCompany(user) {
            // go to current company if set
            if (user.current_company) {
                let company = _.find(user.companies, {id: user.current_company});
                this[SWITCH_TO_ACCOUNT](company);
            } else if (user.companies.length) {
                let company = _.first(user.companies);
                this[SWITCH_TO_ACCOUNT](company);
            } else {
                this[SWITCH_TO_ACCOUNT](null);
            }
            // this.$router.push({name: 'dashboard'});
        },

        copyToClipboard(string) {
            _helper.copyText(string);
            this.$toast.success(this.$t(`Copied`));
        },
        pastFromClipboard(string) {
            document.execCommand('paste');
        },

        dateFormat(dateTime){
            return moment(dateTime).format('DD MMM, YYYY');
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isFloatNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        showNotPermittedMessage(){
            this.$toast.error(this.$t("Sorry you don't have permission for this action."));
        },

        loadApplicantErrorImage(applicant){
            applicant.image = '/assets/images/avatar.png';
        },

        scrollToHash() {
            if (this.$route.hash) {

                let options = {
                    container: 'body',
                    easing: 'ease-in',
                    offset: 0,
                    force: true,
                    cancelable: false,
                    onStart: function(element) {
                        // scrolling started
                    },
                    onDone: function(element) {
                        // scrolling is done
                    },
                    onCancel: function() {
                        // scrolling has been interrupted
                    },
                    x: false,
                    y: true
                };

                VueScrollTo.scrollTo(this.$route.hash, 300, options);
            }
        },

        transformUrlToLink(text = null) {
            if (text) {
                let messageUrlRegex = /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi;
                return text.replace(messageUrlRegex, function (url) {
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    return url.replace(urlRegex, function(mainUrl) {
                        return `<a href="${mainUrl}" style="word-break: break-all;" target="_blank">${mainUrl}</a>`;
                    });
                });
            }
            return text;
        }

    }
}
