import Vue from "vue";
import VueToast from 'vue-toast-notification';
import {isNull} from "lodash";

Vue.use(VueToast, {
    position: 'top-right',
    duration: 3000,
});

export default function canCreateCompany({next, store, from, to, router}) {
    let companies = store.state.user.companies;
    let maxCompanies = store.state.user.package_rules.companies;
    let redirectTo = to.query.redirectTo || null;

    if (!isNull(companies) && !isNull(maxCompanies) && companies.length >= maxCompanies) {

        if (!isNull(redirectTo)) {
            return router.push({name: redirectTo});
        } else {
            Vue.$toast.error("Please contact easy.jobs to upgrade your package.");
            return router.push({
                name: 'my-account'
            });
        }
    }
    return next();
}
