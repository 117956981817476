<template>
    <div class="alert fade show" :class="`alert-${alert.type}`" role="alert" v-if="alert.isShow">
        <button type="button" class="close" @click="hideAlert()">
            <span aria-hidden="true">&times;</span>
        </button>
        {{ alert.message }}
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import { LOAD_ALERT } from '../../constants/action-type'
    export default {
        computed : {
            ...mapState(['alert'])
        },
        mounted() {
            this[LOAD_ALERT]({
                isShow : false,
                message:''
            })
        },
        methods : {
            ...mapActions([LOAD_ALERT]),
            hideAlert() {
                this[LOAD_ALERT]({
                    isShow : false
                })
            }
        },
    }
</script>
