export const vueProgressBar = {
    color: '#597dfc',
    failedColor: '#abbdfe',
    thickness: '3px',
    transition: {
        speed: '0.5s',
        opacity: '0.6s',
        termination: 500
    },
    autoRevert: true,
    location: 'top',
    inverse: false
};

export const vueToast = {
    position: 'top-right',
    duration: 3000,
};

export const vueJsDialog = {
    html: true,
    okText: 'Yes',
    cancelText: 'No',
    animation: 'bounce'
};

export const vueJsLazyLoad = {
    preLoad: 1.3,
    error: '/assets/images/placeholder/error.svg',
    loading: '/assets/images/placeholder/loader.svg',
    attempt: 1,
    throttleWait:1000,
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove'] ,
};

export const ckEditorBasicOptions = {
    toolbar: [ 'bold', 'italic', 'underline', 'strikethrough','link','|', 'bulletedList' ,'numberedList', 'blockQuote', 'fontColor', '|','heading', '|', 'undo', 'redo', '|', 'alignment'],
    heading: {
        options: [
            { model: 'paragraph', title: 'P', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'H2', class: 'ck-heading_heading2' },
        ]
    }
};

export const ckEditorConversationOptions = {
    toolbar: [ 'bold', 'italic', 'underline', 'strikethrough','link','|', 'bulletedList' ,'numberedList'],
    link: {
        addTargetToExternalLinks: true
    }
};


export const radialChartOptions = {
    chart: {
        height: 150,
            type: 'radialBar',
            toolbar: {
            show: false
        }
    },
    plotOptions: {
        radialBar: {
            startAngle: -135,
                endAngle: 225,
                hollow: {
                margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
            },
            track: {
                background: '#f5f7fd',
                    strokeWidth: '100%',
                    margin: 0, // margin is in pixels
            },

            dataLabels: {
                show: true,
                    name: {
                    offsetY: -10,
                        show: false,
                        color: '#888',
                        fontSize: '17px'
                },
                value: {
                    formatter: function (val) {
                        return parseInt(val) + "%";
                    },
                    color: '#111',
                        fontSize: '14px',
                        show: true,
                        offsetY: 5
                }
            }
        }
    },
    stroke: {
        lineCap: 'round'
    },
};

export const datePickerIcons = {
    time: 'eicon e-clock',
    date: 'eicon e-calender',
    up: 'eicon e-arrow-up',
    down: 'eicon e-arrow-down',
    previous: 'eicon e-arrow-left',
    next: 'eicon e-arrow-right',
    today: 'eicon e-checkmark',
    clear: 'eicon e-delete',
    close: 'eicon e-close'
};


export const chartLabelStyles = {
    colors: '#999',
    fontSize: '14px',
    fontWeight: 600,
};

export const chartColors = ['#008ffb', '#00e396', '#feb019', '#ff455f', '#775dd0'];

export const analyticChartOptions = {
    chart: {
        type: 'line',
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 5,
            opacity: 0.2
        },
    },
    legend: {
        show: true,
        labels: {
            colors: chartColors
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth', // smooth, straight
        width: 2,
        colors: chartColors,
    },
    xaxis: {
        type: 'category',
        // type: 'datetime',
        categories: [],
        labels: {
            style: {
                ...chartLabelStyles,
            },
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM",
                day: 'dd MMM',
                hour: 'HH:mm',
            },
        },
        tickPlacement: 'on',

    },
    yaxis: {
        opposite: true,
        min: 0,
        max: function (max) {
            // return Math.ceil(max / 100) * 100;
            return Math.ceil(max / 10) * 10;
        },
        tickAmount: 5,
        labels: {
            style: {
                ...chartLabelStyles,
            }
        }
    },
    markers: {
        size: 5,
        colors: ['#ffffff'],
        strokeWidth: 2,
        strokeColors: chartColors,
        hover: {
            sizeOffset: 2,
        }
    },
    tooltip: {
        shared: true,
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: false,
        },
        // custom: function ({series, seriesIndex, dataPointIndex, w}) {
        //     let colorIndex = seriesIndex % chartColors.length;
        //     let color = chartColors[colorIndex];
        //     let rgba = hexToRgba(color, .1);
        //     return `<div class="price-tooltip" style="color: ${color}; background-color: ${rgba}">
        //                 <span>$${series[seriesIndex][dataPointIndex]}</span>
        //             </div>`;
        // }
    }
};
