export const STATUS = {
    JOB_APPLY_STATUS : {
        PENDING : 0,
        COMPLETE : 1,
        REJECT : 9
    }
};

export const FILTER = {
    JOB_CANDIDATES : {
        NEW : 1,
        RATED : 2,
        NO_RATED : 3,
        ASSIGNED_ASSESSMENT: 4,
        UN_ASSIGNED_ASSESSMENT: 5,
    }
};

export const PERMISSIONS = {
    JOB_VIEW: "job.view",
    JOB_MANAGEMENT: "job.management",
    JOB_PUBLISH: "job.publish",
    CANDIDATE_VIEW: "candidate.view",
    CANDIDATE_DELETE: "candidate.delete",
    CANDIDATE_ORGANIZE: "candidate.organize",
    SETTINGS_COMPANY: "settings.company",
    SETTINGS_TEAM: "settings.team",
    SETTINGS_PIPELINE: "settings.pipeline",
    SETTINGS_EMAIL: "settings.email",
    SETTINGS_CANDIDATE_APPLY: "settings.candidate.apply",
    SETTINGS_SCREENING: "settings.screening",
    SETTINGS_QUIZ: "settings.quiz",
    SETTINGS_ASSESSMENT: "settings.assessment",
    SETTINGS_OTHER: "settings.other"
};

export const DEFAULT_PIPELINE = {
    APPLIED: 0,
    SHORT_LISTED: 1,
    INTERVIEW: 2,
    REMOTE_INTERVIEW: 99,
    REJECTED: "rejected",
    SELECTED: "selected"
};

export const RESPONSE_CODE = {
    SUCCESS: 200,
    FAILED: 400,
    UNAUTHORISED: 403,
    PAYMENT_REQUIRED: 402,
    PRECONDITION_FAILED: 412,
    UNAUTHORISED_ACCESS: 480,
    LOGIN_REQUIRED: 401,
    APPLICANT_DELETED: 470,
    CONFLICT_RELOAD: 499,
    PACKAGE_LIMIT_EXCEEDED: 490
};

export const PIPELINE_TABS = {
    THUMB: 1,
    BOARD: 2
};

export const USER_TYPE = {
    EMPLOYER: 'employer',
    CANDIDATE: 'candidate'
};

export const GENDER = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
};

export const COOKIES = {
    AFFILIATE: 'ej_fp_affiliate',
};
