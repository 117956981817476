import { hasRoutePermission } from "../../config/permission";
import Vue from "vue";
import VueToast from 'vue-toast-notification';
import { isNull } from "lodash";

Vue.use(VueToast, {
    position: 'top-right',
    duration: 3000,
});

export default function routePermission({ to, from, next, store, router }) {

    let currentRouteName = to.name;
    let previousRouteName = from.name || 'dashboard';
    let company = store.state.company;

    if (!isNull(company) && !hasRoutePermission(currentRouteName, company.permission)) {
        Vue.$toast.error("Sorry you don't have permission for this action.");
        return router.push({
            name: previousRouteName
        });
    }

    return next();
}
